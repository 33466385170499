import { Bucket } from './bucket';

export enum LocalStorageKey {
  lastProjectId = 'last_project_id',
  bannerWasClosed = 'banner_was_closed',
  headerAIWasClosed = 'header_ai_was_closed',
  connectorType = 'connector_type',
  scenarioPos = 'scenario_pos',
  authAB = 'auth_a_b',
  paymentStateBeforePayment = 'payment_state_before_payment',
  wsAuthToken = 'ws_auth_token',
  shopbackToken = 'shopback_token',
  paymentModalInAIWasOpened = 'payment_modal_in_ai_was_opened',
  onboardingBotTested = 'onboarding_bot_tested',
  onboardingBotReady = 'onboarding_bot_ready',
  cabinetInMenuOpened = 'cabinet_in_menu_opened',
  menuOpened = 'menu_opened',
  scenariosActionsOpened = 'scenarios_actions_opened',
  pinnedVarColumns = 'pinned_var_columns'
}

export enum CookieKey {
  lng = 'lng'
}

export const createScenarioPosKey = ({
  id,
  bucket
}: {
  id: string;
  bucket: Bucket;
}): string => `scenario_${bucket}_${id}`;

export const createShopbackTokenKey = (projectId: string): string =>
  `${LocalStorageKey.shopbackToken}_${projectId}`;

export const createWsAuthTokenKey = (projectId: string): string =>
  `${LocalStorageKey.wsAuthToken}_${projectId}`;

export const createOnboardingBotTestedKey = (projectId: string): string =>
  `${LocalStorageKey.onboardingBotTested}_${projectId}`;

export const createOnboardingBotReadyKey = (projectId: string): string =>
  `${LocalStorageKey.onboardingBotReady}_${projectId}`;

export const createScenariosActionsOpenedKey = (projectId: string): string =>
  `${LocalStorageKey.scenariosActionsOpened}_${projectId}`;

export const createPinnedVarColumnsKey = (projectId: string): string =>
  `${LocalStorageKey.pinnedVarColumns}_${projectId}`;
